import React, { useContext } from "react";
import styled from "@emotion/styled";
import redPlayButton from "../images/icon-play-button-red.svg";
import { StoreContext } from "../store";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  img {
    margin-right: 20px;
  }

  .title {
    text-align: left;
  }
`;

const PlayInitializer = ({ track, title, subTitle }) => {
  const { clearCurrentTrack, playTrack, onAudioInit } = useContext(
    StoreContext
  );

  const handleClick = async (track) => {
    await clearCurrentTrack();
    await onAudioInit({
      url: track,
      title: title,
      subTitle: subTitle,
    });
  };

  return (
    <StyledButton className="play-init-wrap" onClick={() => handleClick(track)}>
      <img src={redPlayButton} alt="Play Audio" />
      <div className={"title"}>Play {title}</div>
    </StyledButton>
  );
};

export default PlayInitializer;
