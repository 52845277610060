import React, { useContext } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import CopyrightText from "../components/CopyrightText";
import styled from "@emotion/styled";
import HeadingWithSub from "../components/HeadingWithSub";
import Accordion from "../components/Accordion";
import { colors } from "../utilities/themeVars";
import { StoreContext } from "../store";
import PlayInitializer from "../components/PlayInitializer";
import Seo from "../components/seo";
import ParentRouteHandler from "../components/ParentRouteHandler";
import { customBackHandler } from "../utilities/helpers";

const Container = styled.div`
  padding-bottom: 50px;

  .header-image {
    margin-bottom: 30px;
  }

  .play-init-wrap {
    margin-bottom: 20px;
  }

  .details {
    margin-top: 30px;
  }

  .detail {
    margin-bottom: 4px;
  }

  .copyright-wrap {
    margin: 30px 0;
  }

  .accordion-text {
    margin: 20px 0;
  }

  .additional-info-wrap {
    border-top: solid 1px ${colors.gray3};
  }

  .back-to-list {
    position: fixed;
    bottom: ${(props) => (props.trackUrlNotNull ? "120px" : "20px")};

    left: 0;
    right: 0;
    padding: 0 10px;

    button,
    a {
      display: block;
      outline: none;
      background: ${colors.primary};
      color: ${colors.white};
      padding: 10px;
      text-align: center;
      margin: auto;
      width: 100%;
    }
  }
`;

const TourStopTemplate = (props) => {
  const { nodeTourStop: tour_stop, allTaxonomyTermTours } = props.data;
  const { audioDetails, parentRouteDetails } = useContext(StoreContext);
  const { url: trackUrl } = audioDetails;
  const { location } = props;
  const urlParams = location.search;

  const mp3Exists =
    tour_stop.rel.field_audio_tour &&
    tour_stop.rel.field_audio_tour.rel.field_media_audio_file.localFile &&
    tour_stop.rel.field_audio_tour.rel.field_media_audio_file.localFile
      .publicURL;

  const artworkExists = tour_stop.rel.artwork[0];

  const tourImageExists =
    tour_stop.rel.tour_image &&
    tour_stop.rel.tour_image[0] &&
    tour_stop.rel.tour_image[0].localFile;

  const mainTitle = artworkExists
    ? tour_stop.rel.artwork[0].title
    : tour_stop.title;

  const subTitle = artworkExists
    ? tour_stop.rel.artwork[0].rel.artist[0].artist_name
    : null;

  const cameDirectlyFromArtworkPage = location.search.includes("fromTour=1");

  const parentTourGroup = allTaxonomyTermTours.nodes[0];
  const parentRoute = `${parentTourGroup.path.alias}${urlParams}`;

  const parentTitle = cameDirectlyFromArtworkPage
    ? "Back"
    : `${parentTourGroup.field_mobile_display_name}`;
  return (
    <div>
      <Seo title={mainTitle} />

      <ParentRouteHandler title={parentTitle} path={parentRoute} />

      <Container trackUrlNotNull={trackUrl}>
        <div className={"content"}>
          <div className="header-image">
            {artworkExists && (
              <>
                {
                  tour_stop.rel.artwork[0].rel.image ? (
                    <GatsbyImage
                      image={getImage(
                        tour_stop.rel.artwork[0].rel.image?.rel.media.localFile
                      )}
                      alt={tour_stop.rel.artwork[0].title ? tour_stop.rel.artwork[0].title : null}
                    />
                  ) : (
                    <StaticImage src="../images/artwork-placeholder.jpg" height={500} width={500} alt="placeholder" />
                  )
                }
              </>
            )}

            {!artworkExists && tourImageExists && (
              <GatsbyImage
                image={getImage(tour_stop.rel.tour_image[0].localFile)}
                alt={tour_stop.rel.tour_image[0] ?  tour_stop.rel.tour_image[0] : "Tour Image"}
              />
            )}

            {!artworkExists && !tourImageExists && (
              <StaticImage src="../images/artwork-placeholder.jpg" height={500} width={500} alt="placeholder" />
            )}
          </div>

          {mp3Exists && (
            <PlayInitializer
              title={tour_stop ? tour_stop.title : null}
              subTitle={subTitle}
              track={
                tour_stop.rel.field_audio_tour.rel.field_media_audio_file
                  .localFile.publicURL
              }
            />
          )}

          <HeadingWithSub main={mainTitle} subTitle={subTitle} />
          <div className="details">
            {artworkExists && (
              <>
                <div className={"detail"}>
                  {tour_stop.rel.artwork[0].field_label_date}
                </div>
                <div className={"detail"}>
                  {tour_stop.rel.artwork[0].field_media_and_support}
                </div>
                <div className={"detail"}>
                  {tour_stop.rel.artwork[0].field_label_dimensions}
                </div>
                <div className={"detail"}>
                  {tour_stop.rel.artwork[0].field_artwork_image_credit}
                </div>

                <div className="copyright-wrap">
                  <CopyrightText
                    field_artwork_copyright={
                      tour_stop.rel.artwork[0].field_artwork_copyright
                    }
                    artist_copyright={
                      tour_stop.rel.artwork[0].rel.artist[0].copyright
                    }
                    artist_name={
                      tour_stop.rel.artwork[0].rel.artist[0].artist_name
                    }
                  />
                </div>
              </>
            )}
          </div>
          <div className="additional-info-wrap">
            {tour_stop.field_audio_transcript && (
              <div className={"transcript-wrap"}>
                <Accordion title={"Audio Transcript"}>
                  <div
                    className={"accordion-text"}
                    dangerouslySetInnerHTML={{
                      __html: tour_stop.field_audio_transcript.processed,
                    }}
                  />
                </Accordion>
              </div>
            )}
            {artworkExists && tour_stop.rel.artwork[0].field_label_text[0] && (
              <div className={"artwork-info-wrap"}>
                <Accordion title={"About This Artwork"}>
                  <div
                    className={"accordion-text"}
                    dangerouslySetInnerHTML={{
                      __html:
                        tour_stop.rel.artwork[0].field_label_text[0].processed,
                    }}
                  />
                </Accordion>
              </div>
            )}
            {artworkExists &&
              tour_stop.rel.artwork[0].rel.artist[0].about_artist && (
                <div className={"artist-info-wrap"}>
                  <Accordion title={"More About This Artist"}>
                    <div
                      className={"accordion-text"}
                      dangerouslySetInnerHTML={{
                        __html:
                          tour_stop.rel.artwork[0].rel.artist[0].about_artist
                            .processed,
                      }}
                    />
                  </Accordion>
                </div>
              )}
          </div>
        </div>

        {allTaxonomyTermTours.nodes[0] && (
          <div className={"back-to-list"}>
            <button onClick={() => customBackHandler(parentRouteDetails)}>
              {parentTitle.toLowerCase() === "back"
                ? parentTitle
                : `Back To ${parentTitle}`}
            </button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default TourStopTemplate;

export const query = graphql`
  query tourStop($drupal_id: String!) {
    allTaxonomyTermTours(
      filter: {
        relationships: {
          node__tour_stop: { elemMatch: { drupal_id: { eq: $drupal_id } } }
        }
      }
    ) {
      nodes {
        name
        field_mobile_display_name
        path {
          alias
        }
      }
    }

    nodeTourStop(drupal_id: { eq: $drupal_id }) {
      title
      field_audio_transcript {
        processed
      }
      rel: relationships {
        field_audio_tour {
          rel: relationships {
            field_media_audio_file {
              localFile {
                url
                publicURL
              }
            }
          }
        }
        tour_image: field_light_box_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        artwork: field_related_artwork {
          title
          field_artwork_copyright
          field_artwork_image_credit
          field_label_date
          field_label_text {
            processed
          }
          field_media_and_support
          field_label_dimensions
          rel: relationships {
            artist: field_artist_ {
              artist_name: title
              copyright: field_default_artwork_copyright {
                processed
              }
              about_artist: body {
                processed
              }
            }
            image: field_preview_sharing_image_med {
              rel: relationships {
                media: field_media_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
