import React from 'react';

const CopyrightText = props => (
  <>
    {/*Print field_artwork_copyright is available*/}
    {props.field_artwork_copyright && (
      <div>{props.field_artwork_copyright}</div>
    )}

    {/*If not, print artist_copyright*/}
    {props.artist_copyright && !props.field_artwork_copyright && (
      <div
        dangerouslySetInnerHTML={{ __html: props.artist_copyright.processed }}
      />
    )}

    {/*As a last resort, print artist name with copyright symbol.*/}
    {!props.field_artwork_copyright && !props.artist_copyright && (
      <div>&copy; {props.artist_name}</div>
    )}
  </>
);

export default CopyrightText;
