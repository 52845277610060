import React, { useState } from "react";
import styled from "@emotion/styled";
import { colors } from "../utilities/themeVars";

const AccordionContent = styled.div`
  height: ${(props) => (props.open ? `auto` : `0`)};
  overflow: hidden;
`;

const Container = styled.div``;

const StyledButton = styled((props) => <button {...props} />)`
  display: block;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: ${colors.primary};
  border-bottom: solid 1px ${colors.gray3};
  padding: 18px 0;
  outline: none;

  span {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      right: -15px;
      width: 10px;
      height: 1px;
      background: ${colors.primary};
      content: "";
    }

    &:after {
      transform: ${(props) => (props.open ? `rotate(0deg)` : `rotate(-90deg)`)};
      //transition: transform 0.1s ease;
    }
  }
`;

const Accordion = ({ title, children, defaultOpen }) => {
  const [open, setAccordionOpen] = useState(!!defaultOpen);
  const onClick = () => {
    setAccordionOpen(!open);
  };

  return (
    <Container>
      <StyledButton onClick={() => onClick()} open={open}>
        <span>{title}</span>
      </StyledButton>
      <AccordionContent open={open}>{children}</AccordionContent>
    </Container>
  );
};

export default Accordion;
